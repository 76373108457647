export default function(target, trigger) {
    // const menu = $('.mobile-menu__nav');

    //set initial depth on open
    $(trigger).on('click touch', function(e) {
        e.preventDefault();
        $(trigger).toggleClass('is-active');
        $(target).toggleClass('is-active');
        $('body').toggleClass('no-scroll');
        // menu.attr('data-level', 1);
    })

    // $('.mobile-menu__nav .mobile-menu__parent .mobile-menu__next').on('click touch', function (e){
    //     e.preventDefault();
    //     $(this).parent().parent().toggleClass('is-active');
    //     $('body, html').toggleClass('no-scroll')
    //     $('.mobile-menu__nav').attr('data-level', 2);
    // })
    
    // $('.mobile-menu__back').on('click touch', function (e){
    //     e.preventDefault();
    //     $('.mobile-menu__nav').attr('data-level', 1);
    //     $('.mobile-menu__nav .mobile-menu__parent').removeClass('is-active');
    // })
}