export default {
  init() {
    const gallery = $('.more-posts--slider');
    
    if(gallery && $(gallery).length > 0) {
      $(document).ready(function() {
        $(gallery).slick({
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          lazyLoad: 'ondemand',
          adaptiveHeight: true,
          dots: true,
          arrows: false,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
              },
            },
          ],
        });
      })
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
