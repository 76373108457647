import LatestPosts from '../inc/latest-posts';

export default {
  init() {
    // JavaScript to be fired on the home page
    LatestPosts.init()
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
