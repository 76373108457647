export default function(gallery = null) {
    // Inline SVG
    if(gallery && $(gallery).length > 0) {
      $(document).ready(function() {
        $(gallery).slick({
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          lazyLoad: 'ondemand',
          adaptiveHeight: true,
          dots: true,
          arrows: false,
        });
      })
    }
}