export default {
    init() {
        $('.latest-posts__item').on('mouseover', function() {
            $('.latest-posts__item').not($(this)).removeClass('is-active');
            $(this).addClass('is-active');
            const src = $(this).data('src');
            const srcset = $(this).data('srcset');
            const title = $(this).data('title');
    
            $('#latest-posts__img').attr('src', src);
            $('#latest-posts__img').attr('srcset', srcset);
            $('#latest-posts__img').attr('title', title);
        })
    },
}