export default {
  init() {
    $('.ma-form__profile-wrap').on('click touch', function(e){ 
      e.preventDefault(); 
      $('#ma-form__profile-upload').trigger('click'); 
    });

      function readURL(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
    
            reader.onload = function (e) {
                $('.ma-form__profile-avatar').attr('src', e.target.result);
                $('.ma-form__profile-avatar').attr('srcset', e.target.result);
                if($('.ma-form__profile-avatar--empty').length > 0 ){
                  $('.ma-form__profile-avatar--empty').addClass('mb-10');
                  $('.ma-form__profile-avatar--empty').removeClass('d-none');
                }
            }
    
            reader.readAsDataURL(input.files[0]);
        }
    }
    
      $('#ma-form__profile-upload').on('change', function(){
          if($(this)[0].files[0].size > 250000) {
            alert('Your logo file is too large. It must be 250kb or less');
          } else {
            readURL(this);
          }
      });

      // $('.ma-form__remove-logo').on('click touch', function(e) {
      //   e.preventDefault();
      //   $('#ma-form__profile-upload').val('');
      //   $('.ma-form__profile-avatar').addClass('d-none');
      //   $('.ma-form__profile-avatar').addClass('.ma-form__profile-avatar--empty');
      // })
  },
};
