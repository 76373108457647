import Gallery from '../inc/gallery';

export default {
  init() {
    // Init Libraries
    Gallery('.related-products-slider');
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
