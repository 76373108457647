// import external dependencies
import 'jquery';
import 'custom-event-polyfill';

// Import everything from autoload
import './autoload/_bootstrap.js';
import 'slick-carousel/slick/slick.min';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
// import 'lazysizes/plugins/blur-up/ls.blur-up';

// Import & set up web fonts
// const WebFont = require('webfontloader');
// WebFont.load({
//   custom: {
//     families: ['Gilroy:n4,i4,n5,n6,i6,n7'],
//     urls: ['./styles/main.css'],
//   },
// });


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
// import aboutUs from './routes/about';
import single from './routes/single';
import singleProduct from './routes/single-product';
import myAccount from './routes/my-account';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  // aboutUs,
  myAccount,
  single,
  singleProduct,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
