import 'jquery-nice-select';
import 'waypoints/lib/jquery.waypoints.min'
import 'waypoints/lib/shortcuts/sticky.min'
import {WOW} from 'wowjs'
import objectFitImages from 'object-fit-images';
import InlineSVG from '../inc/inline-svg';
import Gallery from '../inc/gallery';
import btnLoader from '../inc/btn-loader';
// import maxCharacters from '../inc/max-characters';
import MobileMenu from '../inc/mobile-menu';


export default {
  init() {
    // Init Libraries
    const wow = new WOW();
    wow.init();
    objectFitImages();
    InlineSVG();
    Gallery('.cb-gallery');
    $('select').not('#billing_country, #billing_state').niceSelect();
    btnLoader('.btn-loader');
    MobileMenu('.mobile-menu', '.mobile-menu__trigger');

    // Sticky Header
    if($('.header-primary').length > 0) {
      // eslint-disable-next-line
      const header = new Waypoint.Sticky({
        element: $('.header-primary')[0],
        offset: '-200px',
      });
    }

    if($('.event-slider').length > 0) {
      $(document).ready(function() {
        $('.event-slider').slick({
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          lazyLoad: 'ondemand',
          adaptiveHeight: false,
          dots: true,
          arrows: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                  },
              },
          ],
        });
      })
    }

  //   const dropdownToggle = document.querySelector('.dropdown-toggle');
  // dropdownToggle.addEventListener('click', function(event) {
  //   event.preventDefault();
  // });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
